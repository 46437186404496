import React from 'react';
import Header from "../components/header";
import Hero from "../components/hero";
import Seo from "../components/seo/seo";

const Index = props => {
  return (
    <>
      <Seo />
      <Header />
      <Hero />
    </>
  );
};

export default Index;